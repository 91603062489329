// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  float: right;
  margin: 10px;
}

.img-fluid {
  float: right;
  margin-left: 20px;
}

#date {
  float: left;
  font-style: italic;
  margin: 20px;
}

.heading {
  text-decoration: underline;
}

.file {
  margin: 20px;
  text-align: center;
}

.fileheading {
  margin-top: 30px;
  text-align: center;
}

.row {
  float: left;
  margin: 20px;
  border: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/small-molecule/pdf-layout/pdf-layout.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;AACJ;;AAEC;EACI,YAAA;EACA,iBAAA;AACL;;AAOC;EACI,WAAA;EACA,kBAAA;EACA,YAAA;AAJL;;AAOG;EACC,0BAAA;AAJJ;;AAQG;EACE,YAAA;EACA,kBAAA;AALL;;AAQG;EACE,gBAAA;EACA,kBAAA;AALL;;AAQG;EACC,WAAA;EACA,YAAA;EACA,WAAA;AALJ","sourcesContent":[".btn{\r\n    float: right;\r\n    margin: 10px;\r\n }\r\n \r\n .img-fluid{\r\n     float: right;\r\n     margin-left: 20px;\r\n }\r\n // table thead th { \r\n //     color: white;\r\n //      font-size:10pt;\r\n //       background-color: #3b5998\r\n //     }\r\n \r\n #date{\r\n     float: left;\r\n     font-style: italic;\r\n     margin:20px ;\r\n   }\r\n \r\n   .heading{\r\n    text-decoration: underline;\r\n   }\r\n \r\n  \r\n   .file{\r\n     margin: 20px;\r\n     text-align: center;\r\n   }\r\n \r\n   .fileheading{\r\n     margin-top: 30px;\r\n     text-align: center;\r\n \r\n   }\r\n   .row{\r\n    float: left;\r\n    margin: 20px;\r\n    border: 2px;\r\n   }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
