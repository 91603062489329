import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Rx';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, DBService, DownLoadService } from '../../service';
import { Result, Table, outputGraph } from '../../model/small-molecule.model';
import { AnalysisDetails, NewTable, SearchResult } from '../../model/analysis-details.model';
// import { NgxXml2jsonService } from 'ngx-xml2json';
import { SharedService } from '../../layouts/shared-service';
import { User } from '../../model/types';
import { DropDownService } from '../../service/drop-down.service';
import { MatPaginator } from '@angular/material/paginator';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
// import { GraphDialogComponent } from './graph-dialog/graph-dialog.component';
import { AppLoaderService } from '../../service/app-loader/app-loader.service';
import { interval } from 'rxjs/internal/observable/interval';
import { error } from 'console';
import { bool } from 'aws-sdk/clients/signer';
import { ColumnDialogComponent } from './column-dialog/column-dialog.component';
import { AuthNewService } from '../../service/auth-new.service';

@Component({
    selector: 'app-small-molecule',
    templateUrl: './small-molecule.component.html',
    styleUrls: ['./small-molecule.component.scss']
})
export class SmallMoleculeComponent implements OnInit, OnDestroy {

    pageTitle = 'Analyze Report/Data';
    signedInUser: any;
    analysisId = '';
    searchResult: SearchResult = <SearchResult>{};
    // analysisResponseNew: SearchResult = <SearchResult>{};
    selectedTables: string;
    analysisDetails: AnalysisDetails;
    public tables: Table[] = [];
    tablesKey: any = [];
    tablesValue: any = [];
    stattablesKey: any = [];
    stattablesValue: any = [];
    tablesList: NewTable[];
    tablesNotFound: any = [];
    tableData: any;
    tableAnalysisType: string;
    routersub: ISubscription;
    callerSubscription: Subscription;
    tableSelected = false;
    tableProcessed = false;
    reviewTableSelection = false;
    public results: Result[];
    tempData: any;
    title = 'Loading';
    mysubscription: any;
    visibleData = 0;
    public showcharts = false;
    item;
    output_graph: outputGraph[];
    graphs: any[] = [];
    // public Histograms = [];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    // @ViewChild('textData') data: ElementRef;

    step = 0;
    updateOptions: Object;
    data: any;
    options: {};
    navigationSubscription: Subscription;
    usr: any;
    formType: any;
    currentUser: User;
    illegalUser: boolean;
    sData: SearchResult;
    checked: boolean = false;
    disabled: boolean = false;
    displayTable: boolean = false;
    isFetching: boolean = true;
    displayPDFData: boolean = true;
    displayExPanels: boolean = true;
    searchResultData: boolean = true;
    errortable: any;
    extractrunid = [];
    columnArray = [];
    dataArray = [];
    jsonDataArray = [];
    displayObservation: boolean = false;
    keysWithTrueValue = [];
    tableId = [];
    identifiedColumnsArray: any[] = [];
    signedInUserAccess;
    private singleSearchData: Subscription;
    private currentLoginUser: Subscription;

    constructor(private _sharedService: SharedService,
        private activatedroute: ActivatedRoute,
        private AuthService: AuthNewService,
        private elRef: ElementRef,
        private router: Router,
        private dbService: DBService,
        private dropDownService: DropDownService,
        private downloadService: DownLoadService,
        public dialog: MatDialog,
        // private ngxXml2jsonService: NgxXml2jsonService,
        private ref: ChangeDetectorRef,
        private loader: AppLoaderService,
    ) {
        this._sharedService.emitChange(this.pageTitle);
        // if(this.tables.length==0){
        //     this.openDialog()
        // }


        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // console.log(e)
            if (e instanceof NavigationEnd) {
                this.initialiseInvites();
            }
        });




    }


    initialiseInvites() {
        this.AuthService.getCurrentUser((err, user: User) => {
            this.currentUser = user;
            // console.log(user)
            this.activatedroute.paramMap.subscribe((params) => {

                if (params.get('user') !== this.currentUser.username) {
                    return
                } else {
                    this.usr = params.get('user');
                    this.analysisId = params.get('id');
                }

            });
        });

    }

    ngOnInit() {
        this.pollForResult();

        this.singleSearchData = this.dbService.singleSearch.subscribe(
            (data: SearchResult) => {


                if (Object.keys(data).length === 0) {
                    this.searchResultData = false;
                    console.log('Object is empty');
                } else {
                    this.isFetching = false;
                    console.log('Object is NOT empty');
                }

                data && this.populateResultData(data);

                console.log(data)
            }
        );
        sessionStorage.removeItem('xyz');

    }

    ToggleState(event) {
        this.displayTable = !this.displayTable;
        // console.log(this.displayTable, event)
    }
    ToggleState2(event) {
        this.displayObservation = !this.displayObservation;
        // console.log(this.displayTable, event)
    }

    ToggleState1(event) {
        this.displayPDFData = !this.displayPDFData;
        this.displayExPanels = !this.displayExPanels;
        // this.displayObservation = false;
        this.displayTable = false;
        console.log(this.analysisDetails)
    }



    openModal() {
        const columndata = {
            id: this.analysisId,
            username: this.usr,
            tableid: this.tableId,
            unidentified_columns: this.keysWithTrueValue,
            identifiedColumnsArray: this.identifiedColumnsArray,
            analysisDetails: this.analysisDetails
        }
        const dialogRef = this.dialog.open(ColumnDialogComponent, {
            width: '600px',
            data: columndata,

        });

        dialogRef.afterClosed().subscribe(result => {
            // Handle actions after the modal is closed (if needed)
            console.log('Modal closed with result:', result);
           
        });
    }

    openDialog() {
        this.loader.open(this.title);

    }

    closeDialog() {
        // console.log("dialog closed")
        this.loader.close();
    }

    // ngAfterViewInit() {
    //     let li = this.data.nativeElement.value;
    //     console.log("this value is", li);
    // }
    showCharts() {
        this.showcharts = true;
    }

    pollForResult() {
        this.AuthService.getCurrentUser();
        this.currentLoginUser = this.AuthService.getCurrentLoginUser.subscribe((user: any) => {
            this.signedInUser = user;
            if (!this.signedInUser) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            } else {
                this.signedInUserAccess = this.signedInUser.attributes["custom:access"].toLowerCase();
                this.activatedroute.paramMap.subscribe((params) => {
                     if (this.signedInUserAccess == 'su' || this.signedInUserAccess == 'ga' || this.signedInUserAccess == 'qa'|| this.signedInUserAccess == 'pi' ) {
                        this.usr = params.get('user');
                        this.analysisId = params.get('id');
                        this.dbService.getAnalysisDetails(this.usr, this.analysisId);
                        this.illegalUser = false;
                    }

                });
            }

        })
        this.ref.detectChanges();
    }



    setStep(index: number) {
        this.step = index;

    }

    nextStep(table: Table, selection: boolean) {
        table.valid = selection;
        this.step++;

        for (const obj of this.tables) {
            if (obj.valid === true || obj.valid === false) {
                this.tableSelected = true;
            } else {
                this.tableSelected = false;
                break;
            }
        }
    }

    submitTableData() {
        this.tableProcessed = true;
        this.reviewTableSelection = false;
        this.dbService.submitTableData(this.tables, this.signedInUser.username, this.analysisId).subscribe((data: Result[]) => {
            this.pollForResult();
        });
    }

    getCount(result: Result, color: string) {
        let count = 0
        for (const resultItem of result.resultItem) {
            for (const items of resultItem.item) {
                if (items.color === color) {
                    count = count + items.message.length
                }
            }
        }
        return count;
    }

    getColor(level: string): string {
        if (level === 'Green') {
            return '#C5EEB2'
        } else if (level === 'Yellow') {
            return '#F8F8A9'
        } else if (level === 'Info') {
            return '#75cff8'
        } else if (level === 'Red') {
            return '#EEC2B7'
        }
    }


    processResult() {
        // console.log('Result Prcessor');

        for (const result of this.results) {
            for (const resultItem of result.resultItem) {
                for (const item of resultItem.item) {
                    item.colorCode = this.getColor(item.color);
                }
            }
        }
        this.formType = this.searchResult.analysisType;
        try {
            this.output_graph = JSON.parse(this.searchResult.AnalysisResultGraph);
        } catch (error) {
        }
    }
    findKey(table) {
        //    this.arraytoJson(table)
        return table.hasOwnProperty('error')
    }

    //     checkRunIdValue( cellvalue) {
    //         // console.log(cellvalue,"ftgh")
    //         if (cellvalue.key === 'run_id') {
    //             return cellvalue.value > 5
    //         }
    //         return false
    //     }
    // arraytoJson(table){
    //     this.columnArray = table.table_data.columns
    //     this.dataArray = table.table_data.data
    //     // console.log(this.columnArray);
    //     // console.log(this.dataArray)
    //     this.jsonDataArray = this.dataArray.map((rowData) => {
    //         return rowData.reduce((result, value, index) => {
    //             result[this.columnArray[index]] = value;
    //             return result;
    //         }, {});
    //     });
    //     console.log(this.jsonDataArray)

    // }
    populateResultData(data: SearchResult) {
        this.tablesList = [];
        this.searchResult = data;
        // console.log(this.searchResult);
        this.tableAnalysisType = this.searchResult.analysisSubtype;
        this.tablesList = this.searchResult.Tables;
        this.tablesNotFound = this.searchResult.MissingTables;
        const status = this.searchResult.analysisStatus;
        const columnsKey = this.searchResult?.Tables?.map(res => res.identified_columns);
        this.keysWithTrueValue = []
        columnsKey?.forEach(
            res => {
                for (const key in res) {
                    if (res[key] === true) {
                        this.keysWithTrueValue.push(key);
                    }
                }

            })
        console.log(this.keysWithTrueValue)
        this.tableId = this.searchResult.Tables?.map(res => res.table_id);

        this.tableId = Array.from(new Set(this.tableId));
        console.log(this.tableId)

        this.identifiedColumnsArray = [];

        this.tablesList?.forEach((item, index) => {
            const tableId = item.table_id;
            const tableType = item.table_type;
            const identifiedColumns: string[] = [];
            const combinedColumns = {};
            for (const column in item.identified_columns) {
                if (item.identified_columns[column]) {
                    identifiedColumns.push(column);
                }
            }
            if (identifiedColumns.length > 0) {
                const resultItem = {
                    table_id: tableId,
                    table_type: tableType,
                    identified_columns: identifiedColumns,
                };
                this.identifiedColumnsArray.push(resultItem);

                this.identifiedColumnsArray?.forEach(item => {
                    const { table_id, table_type, identified_columns } = item;
                    const key = `${table_id}_${table_type}`;

                    if (combinedColumns[key]) {
                        // Merge the identified_columns arrays and remove duplicates
                        combinedColumns[key].identified_columns = Array.from(
                            new Set([...combinedColumns[key].identified_columns, ...identified_columns])
                        );
                    } else {
                        combinedColumns[key] = { table_id, table_type, identified_columns: [...identified_columns] };
                    }
                });
                this.identifiedColumnsArray = Object.values(combinedColumns);
            }
        });

        console.log(this.identifiedColumnsArray)

        // TableSelected, NewAnalysis, Complete
        if (this.searchResult.analysisStatus === 'TableSelected' || this.searchResult.analysisStatus === 'Processed') {
            this.openDialog()
        }
        if (this.searchResult.analysisStatus === 'Complete' || this.searchResult.analysisStatus === 'Error') {
            this.closeDialog()
        }

        this.analysisDetails = JSON.parse(this.searchResult?.analysisDetails);
        console.log(this.analysisDetails)
        if (status === 'TableResult') {

            if ((this.searchResult.Tables?.length > 4)) {

                // console.log('this.searchResult.Tables.length ', this.searchResult.Tables.length)

            }

        } else if (status === 'TableSelected') {
            this.selectedTables = this.searchResult.selectedTables;
            this.pollForResult()
        } 
        // else if (status === 'Complete') {
        //     if ((this.searchResult.Tables.length > 4)) {
        //     }
        //     this.results = JSON.parse(this.searchResult.analysisResult);
        //     this.processResult();

        // } 
        else if (status === 'Error') {
            console.log('Error occured');
        }
    }

    tableFlag(text: string) {
        const flag = text.startsWith('<table') || text.startsWith('graph');
        return flag

    }

    getDisplayString(key) {
        return this.dropDownService.nameDescMap.get(key);
    }

    getSingedURL(path: string) {
        if (path.indexOf('https') !== -1) {
            return path;
        } else if (/\S/.test(path)) {
            return this.downloadService.getUrl(path);
        } else {
            return ' ';
        }

    }

    review() {
        const tableObjects: Table[] = JSON.parse(this.searchResult.selectedTables);
        const table_indexes: string[] = new Array();
        for (const tab of tableObjects) {
            table_indexes.push(tab.table_index)
        }

        for (const table of this.tables) {
            if (table_indexes.includes(table.table_index)) {
                table.valid = true;
            } else {
                table.valid = false;
            }
        }

        this.reviewTableSelection = true;
    }

    openGraph() {
        this.output_graph.forEach((element) => {
            const link = this.getSingedURL(element.key);
            const title = element.title;
            this.graphs.push(link);
        })
        // this.dialog.open(GraphDialogComponent, { data: { name: this.graphs }, disableClose: false, height: '540px', width: '82%' });

    }
    ngOnDestroy() {
        // Unsubscribe from the observable to avoid memory leaks
        if (this.singleSearchData) {
            this.singleSearchData.unsubscribe();
        }
        if (this.currentLoginUser) {
            this.currentLoginUser.unsubscribe();
        }
    }

}
