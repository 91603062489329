// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header {
  text-align: center;
  border-bottom: thin solid #aaaaaa;
  color: #555555;
}

strong {
  color: #555555;
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/dashboard/dashboard.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iCAAA;EACA,cAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA,EAAA,gDAAA;AACJ;;AAEE,iFAAA;AACA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;AACJ","sourcesContent":[".card-header{\r\n    text-align: center;\r\n    border-bottom: thin solid #aaaaaa;\r\n    color: #555555\r\n}\r\n\r\nstrong{\r\n    color: #555555;\r\n}\r\n\r\n.container {\r\n    position: relative;\r\n    overflow: hidden;\r\n    width: 100%;\r\n    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */\r\n  }\r\n  \r\n  /* Then style the iframe to fit in the container div with full height and width */\r\n  .responsive-iframe {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    bottom: 0;\r\n    right: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
