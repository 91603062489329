// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-form-field + .mat-mdc-form-field {
  margin-left: 8px;
}

.inputbox {
  float: right;
  position: absolute;
}

.expan-panel {
  position: relative;
  display: block;
}

label {
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}

.table-display {
  margin-bottom: 10px;
}

.fa-info-circle {
  position: absolute;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/new-analysis/qpcr-report/qpcr-report.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEE;EACE,YAAA;EACA,kBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,cAAA;AACJ;;AAEE;EACE,qBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,mBAAA;AACJ;;AAEE;EACE,kBAAA;AACJ;;AAGE;EACE,UAAA;EACA,eAAA;EACA,eAAA;AAAJ","sourcesContent":[".mat-mdc-form-field + .mat-mdc-form-field {\r\n    margin-left: 8px;\r\n  }\r\n  \r\n  .inputbox{\r\n    float: right;\r\n    position: absolute;\r\n  }\r\n\r\n  .expan-panel{\r\n    position: relative;\r\n    display: block;\r\n  }\r\n\r\n  label{\r\n    display: inline-block;\r\n    text-align: right;\r\n    margin-right: 10px;\r\n  }\r\n\r\n  .table-display{\r\n    margin-bottom: 10px;\r\n  }\r\n\r\n  .fa-info-circle{\r\n    position: absolute;\r\n\r\n  }\r\n\r\n  .error-message {\r\n    color: red;\r\n    font-size: 12px;\r\n    margin-top: 5px;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
