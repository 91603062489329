import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SearchResult } from '../../../model/analysis-details.model';
import { DBService } from '../../../service/DB.service';
import { AuthService } from '../../../service/auth.service';
import { User } from '../../../model/types';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { NgModel } from '@angular/forms';
import { error } from 'console';


@Component({
  selector: 'app-column-dialog',
  templateUrl: './column-dialog.component.html',
  styleUrls: ['./column-dialog.component.scss']
})
export class ColumnDialogComponent implements OnInit {
  isFetching: boolean = true;
  searchResultData: boolean = true;
  currentUser: User;
  usr: any;
  analysisId = '';
  navigationSubscription: Subscription;
  signedInUser: any;
  illegalUser: boolean;
  tableid = [];
  processtype: string = 'Fetch';
  unknowncolumns = [];
  correctColumns = [];
  CorrectColumns = []
  selectedValue: string[] = new Array(this.unknowncolumns.length);
  identifiedColumnsArray
  identifiedColumnsObject
  recievedTablecolumnsData
  selectedTableId: string[];
  selectedTableType: string;
  selectedValues: { [tableId: string]: { [fieldName: string]: string } } = {};
  executionId: string;
  analysisDetails;
  @Output() closeModal = new EventEmitter<string>();
  dropdownData: boolean = false;
  submitModalResult: boolean = false;
  disabledValue:boolean =false


  constructor(
    private dbService: DBService,
    private authService: AuthService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private ref: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ColumnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public columndata: any
  ) {

  }

  ngOnInit(): void {
    console.log(this.columndata);
    this.pollForResult();

  }


  pollForResult() {
    this.authService.getCurrentUser((err, user: User) => {
      this.signedInUser = user;
      // console.log(user)
      if (!this.signedInUser || !this.signedInUser.signedIn) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      } else {
        this.tableid = this.columndata.tableid;

        this.dbService.getColumnNames(this.processtype, this.tableid);
        this.dbService.columnsSearch.subscribe((data: any) => {
          this.dropdownData = true
          this.recievedTablecolumnsData = data.data
        })

        this.usr = this.columndata.username;
        this.analysisId = this.columndata.id;
        this.analysisDetails = this.columndata.analysisDetails;
        this.unknowncolumns = this.columndata.unidentified_columns;
        this.identifiedColumnsObject = this.columndata.identifiedColumnsArray
        console.log(this.identifiedColumnsObject)
      }
    });
    this.identifiedColumnsObject.forEach(item => {
      this.selectedValues[item.table_id] = {};
      item.identified_columns.forEach(fieldName => {
        this.selectedValues[item.table_id][fieldName] = '';
      });
    });
  

  }


  setSelectedTableId(item: any, fieldName: string, selectedValue: string) {
    this.selectedValues[item.table_id][fieldName] = selectedValue;
    this.analysisDetails.selectedValues = this.selectedValues
    console.log(this.selectedValues)
  }



  getColumnsByTableId(tableId: string) {
    //
    const matchingData = this.recievedTablecolumnsData?.find(item => item.table_id === tableId);
    
    return matchingData ? matchingData.columns : [];
  }

  logSelectedValues(length: number = 9) {
    this.submitModalResult = true;
    this.disabledValue = true
    const characters = '0123456789'; // Only numbers
    let randomId = '';
    this.ref.detectChanges();
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex).toString();
    }

    this.analysisDetails.executionId = randomId;
    console.log(this.analysisDetails.executionId)


    this.dbService.submitColumnsData(this.analysisDetails).subscribe(
      (result) => {
        console.log(result)
        window.location.reload();

      },
      (error) => {
        console.log(error)
        this.disabledValue=false
        this.submitModalResult= false;
      }
    )
    this.closeModal.emit();
   
    // this.dialogRef.close();

  }

}
