import { Component, OnDestroy, OnInit } from '@angular/core';
import {SharedService} from '../../layouts/shared-service';
import {Router} from '@angular/router';
import 'rxjs/Rx';

import {DBService} from '../../service';
import {DownLoadService} from '../download';
import {AuthService} from '../../service';
import {User} from '../../model/types';
import {AnalysisKey, PreviousAnalysis, RDSAnalysisData} from '../../model/analysis-details.model';
import {DropDownService} from '../../service/drop-down.service';
import {AppLoaderService} from '../../service/app-loader/app-loader.service'
import Utils from '../../utility/Utils';
import { AuthNewService } from '../../service/auth-new.service';
import { Subscription } from 'rxjs/Rx';

@Component({
    selector: 'app-previous-analysis',
    templateUrl: './previous-analysis.component.html',
    styleUrls: ['./previous-analysis.component.scss']
})
export class PreviousAnalysisComponent implements OnInit,  OnDestroy {

    pageTitle = 'Previous Activities';
    signedInUser: any;
    // previousAnalysisKeys: PreviousAnalysis[] = [];
    previousAnalysisKeys: RDSAnalysisData[] = [];
    pageLoaded = false;
    signedInUserAccess: any;
    private currentLoginUser:Subscription;
    private DataSubscription: Subscription;

    constructor(private _sharedService: SharedService,
                private authService: AuthNewService,
                private router: Router,
                private dbService: DBService,
                private loader: AppLoaderService, ) {
        this._sharedService.emitChange(this.pageTitle);

    }

    openDialog() {
        this.loader.open();
      }



    ngOnInit(): void {

        // this.authService.getCurrentUser((err, user: User) => {
        //     this.signedInUser = user;
        //     console.log('User in Previous Analysis Page is: ', user)
        //     if (!this.signedInUser || !this.signedInUser.signedIn) {
        //         this.router.navigate(['/extra-layout/signin']);
        //         return;
        //     } else {
        //         // this.dbService.getAnalysis('PI');
        //     this.dbService.getUserAnalysis(this.signedInUser.username);
        //     }
        // });


        this.authService.getCurrentUser();
        this.currentLoginUser=this.authService.getCurrentLoginUser.subscribe((user: any) => {          
            this.signedInUser = user;
            if (!this.signedInUser) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            }
            else {
                this.signedInUserAccess = this.signedInUser.attributes["custom:access"].toLowerCase();
                console.log(this.signedInUserAccess)
                this.dbService.getAnalysis(this.signedInUserAccess);
            }
        }
        )

        // this.dbService.userSearch.subscribe(
        //     (data: PreviousAnalysis[]) => {
        //         console.log('Previous Analysis data from backend ', data);
        //         this.previousAnalysisKeys = data;
        //         this.pageLoaded = true;
        //         console.log(this.previousAnalysisKeys)
        //         if(this.pageLoaded==true){
        //             this.closeDialog()
        //           }
        //     }
        // );


        this.DataSubscription=  this.dbService.rdsAnalysisData.subscribe(
            (data: RDSAnalysisData[]) => {
                console.log('Previous Analysis data from backend ', data);
                const arr = data.filter((element => element.user_id === this.signedInUser?.username))
                this.previousAnalysisKeys = arr;
                this.pageLoaded = true;
                console.log(this.previousAnalysisKeys)
                if(this.pageLoaded==true){
                    this.closeDialog()
                  }
            }
        );

    }

    closeDialog() {
        this.loader.close();
      }

    ngOnDestroy(): void {
        if (this.DataSubscription) {
            this.DataSubscription.unsubscribe();
          }
        if (this.currentLoginUser) {
            this.currentLoginUser.unsubscribe();
          }
    }

}
